<template>
  <div class="w-main uk-text-center uk-padding-large uk-padding-remove-horizontal">
    <img src="/assets/logo_mg.svg" class="w-main-logo" alt="">
    <h2 class="w-main-title">Коммерческое предложение</h2>
    <small v-if="!offer.finalize">Подождите минуту, презентация генерируется.<br class="uk-hidden@s">Если она не сгенерировалась, обновите страницу</small>
    <UserPresentationList :modelValue="offer" :human-payload="offer.humanPayload"></UserPresentationList>
  </div>
</template>

<script>
import BatchJobsClient      from "@/services/catalogue/BatchJobsClient.js";
import BatchGenerator       from "@/services/catalogue/BatchGenerator.js";
import UserPresentationList from "@/components/catalog/UserPresentationList";

export default {
  components: {
    UserPresentationList: UserPresentationList
  },
  data() {
    return {
      offer : {id: null},
    }
  },
  props    : {
    article: null,
  },
  jobClient: null,
  generator: null,
  created() {
    if (this.article === '' || !this.article) {
      return this.$router.push('/');
    }

    this.jobClient = new BatchJobsClient();
    this.generator = new BatchGenerator();
    this.generate().then(() => setInterval(() => {
      (!this.offer.finalize || !this.offer.downloadUrl) && this.showDetail()
    }, 1000));
  },
  mounted() {

  },
  methods: {
    showDetail() {
      this.jobClient ? this.jobClient.clientDetail(this.offer.id).then((offer) => {
        this.offer = (offer !== null ? offer : this.offer);
      }) : null;
    },

    generate() {
      return this.generator.clientGenerateRequest(this.article).then((generateResult) => {
        generateResult && (this.offer = generateResult) && this.showDetail();
      });
    },
  }
}
</script>



<style lang="css" scoped>

.w-main{
  background: #EAEDEF;
  height: 100vh;
}
.w-main-logo{
  width: 141px;
}
.w-main-title{
  font-size: 45px;
  line-height: 42px;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  padding: 2rem 0;
  color: #000000;
}

small{
  color: #000000;
  font-size: 14px;
  margin-bottom: 2rem;
  display: block;
}

@media (max-width: 500px) {
  .w-main-title{
    font-size: 25px;
    line-height: 26px;
    text-align: center;
    text-transform: uppercase;
    margin: 0;
    padding: 1.2rem 0;
    color: #000000;
  }
  .w-main-logo{
    width: 70px;
  }

}

</style>

<style>
.uk-spinner>*>* {
  color: red;
}
</style>
